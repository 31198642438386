import React, { useState } from 'react';
import { Select, DatePicker, Button, Row, Col, Typography, Table, Spin, Input, Space } from 'antd';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const SuspiciousActivity = () => {
  // Retrieving data from localStorage
  const Userid = localStorage.getItem('EMPID');
  const empid = localStorage.getItem('empid') || '';
  const uempid = empid.split(',').map(emp => emp.trim());
  const empname = localStorage.getItem('empname') || '';
  const uempname = empname.split(',').map(empn => empn.trim());
  const department = localStorage.getItem('department') || '';
  const udepartment = department.split(',').map(dep => dep.trim());
  const role = localStorage.getItem('role') || '';
  const urole = role.split(',').map(rol => rol.trim());
  const team = localStorage.getItem('team') || '';
  const uteam = team.split(',').map(tea => tea.trim());
  const project = localStorage.getItem('project') || '';
  const uproject = project.split(',').map(pro => pro.trim());

  // State for selected filters
  const [filters, setFilters] = useState({
    empId: [],
    empName: [],
    dept: [],
    role: [],
    project: [],
    team: [],
    dateRange: [],  // To store the selected date range
  });

  const [selectedFilters, setSelectedFilters] = useState([]);

  // New states for startDate and endDate
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // State for holding the API response data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Handle filter changes
  const handleFilterChange = (filterName, selectedValues) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterName]: selectedValues,
    }));
  };

  // Handle date range change
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);

      // Update filters with the new date range
      setFilters(prevFilters => ({
        ...prevFilters,
        dateRange: dates,
      }));
    }
  };

  // Handle Filter button click
  const handleFilterClick = async () => {
    const { empId, empName, dept, role, project, team, dateRange } = filters;

    // Construct a summary of selected filters
    const selected = [];
    if (empId.length) selected.push(`Employee ID: ${empId.join(', ')}`);
    if (empName.length) selected.push(`Employee Name: ${empName.join(', ')}`);
    if (dept.length) selected.push(`Department: ${dept.join(', ')}`);
    if (role.length) selected.push(`Role: ${role.join(', ')}`);
    if (project.length) selected.push(`Project: ${project.join(', ')}`);
    if (team.length) selected.push(`Team: ${team.join(', ')}`);
    if (dateRange.length) selected.push(`Date Range: ${dateRange[0].format('YYYY-MM-DD')} to ${dateRange[1].format('YYYY-MM-DD')}`);

    setSelectedFilters(selected);

    // Prepare the data to be sent to the API
    const data = {
      userId: Userid,  // Send userId from localStorage
      filters: {
        empId,
        empName,
        dept,
        role,
        project,
        team,
        dateRange: dateRange.length ? [dateRange[0].format('YYYY-MM-DD'), dateRange[1].format('YYYY-MM-DD')] : [],
      },
    };

    // Make the API call to send the filters to the backend
    setLoading(true);  // Show loading spinner
    try {
      const response = await fetch('http://3.216.179.47/api/suspecious.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('API Response:', result);
        // Set the fetched data
        setData(result);
      } else {
        console.error('Error sending data to API');
      }
    } catch (error) {
      console.error('Error during API call:', error);
    } finally {
      setLoading(false);  // Hide loading spinner
    }
  };

  // Handle Reset button click
  const handleResetClick = () => {
    setFilters({
      empId: [],
      empName: [],
      dept: [],
      role: [],
      project: [],
      team: [],
      dateRange: [],
    });
    setSelectedFilters([]);
    setStartDate(null);
    setEndDate(null);
  };

  // Get the formatted date for the placeholder when no date range is selected
  const dateRangePlaceholder = startDate && endDate
    ? `${startDate.format('YYYY-MM-DD')} to ${endDate.format('YYYY-MM-DD')}`
    : 'Select Date Range';

  // Dynamically generate columns based on the first item of the fetched data
  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    // Get the keys of the first row in the data
    const keys = Object.keys(data[0]);

    // Generate column definitions with sorting and search functionalities
    return keys.map(key => ({
      title: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter of the key
      dataIndex: key,
      key: key,
      sorter: (a, b) => {
        // Assuming string values, adjust logic for numeric or date fields
        return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${key}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
            >
              Search
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        return record[key] ? record[key].toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
    }));
  };

  const columns = generateColumns(data);

  return (
    <div>
      {/* Filters */}
      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={6}>
          <RangePicker
            size="small"
            style={{ width: '200px', fontSize: '10px', padding: '2px 6px' }}
            onChange={handleDateRangeChange}
            value={filters.dateRange}
            format="YYYY-MM-DD"
            placeholder={[dateRangePlaceholder, dateRangePlaceholder]} // Display the placeholder dynamically
          />
        </Col>
        <Col span={6}>
          <Select
            size="small"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Emp_Id"
            value={filters.empId}
            onChange={(values) => handleFilterChange('empId', values)}
            allowClear
          >
            <Option value="ALL">All</Option>
            {uempid.map((emp) => (
              <Option key={emp} value={emp}>{emp}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            size="small"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Emp_Name"
            value={filters.empName}
            onChange={(values) => handleFilterChange('empName', values)}
            allowClear
          >
            <Option value="ALL">All</Option>
            {uempname.map((name) => (
              <Option key={name} value={name}>{name}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            size="small"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Department"
            value={filters.dept}
            onChange={(values) => handleFilterChange('dept', values)}
            allowClear
          >
            <Option value="ALL">All</Option>
            {udepartment.map((dep) => (
              <Option key={dep} value={dep}>{dep}</Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={6}>
          <Select
            size="small"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Role"
            value={filters.role}
            onChange={(values) => handleFilterChange('role', values)}
            allowClear
          >
            <Option value="ALL">All</Option>
            {urole.map((rol) => (
              <Option key={rol} value={rol}>{rol}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            size="small"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Team"
            value={filters.team}
            onChange={(values) => handleFilterChange('team', values)}
            allowClear
          >
            <Option value="ALL">All</Option>
            {uteam.map((tea) => (
              <Option key={tea} value={tea}>{tea}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            size="small"
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Project"
            value={filters.project}
            onChange={(values) => handleFilterChange('project', values)}
            allowClear
          >
            <Option value="ALL">All</Option>
            {uproject.map((pro) => (
              <Option key={pro} value={pro}>{pro}</Option>
            ))}
          </Select>
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={handleFilterClick} style={{ width: '100%' }}>
            Filter
          </Button>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: '16px' }}>
        <Col span={12}>
          <Button onClick={handleResetClick} style={{ width: '100%' }}>
            Reset Filters
          </Button>
        </Col>
      </Row>

      {/* Display the Table */}
      <div style={{ marginTop: '16px' }}>
        {loading ? (
          <Spin size="large" />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            rowKey="empId"
            scroll={{ x: 'max-content' }} // Enable horizontal scroll if necessary
            pagination={{ pageSize: 10 }}
          />
        )}
      </div>
    </div>
  );
};

export default SuspiciousActivity;
