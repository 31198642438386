import React, { useState } from 'react';
import { Tabs } from 'antd';

// Import the components for each tab
import SuspiciousActivity from './SUSPICIOUS_ACTIVITY'; // Suspicious Activity Component
import UsbActivity from './USB_ACTIVITY'; // USB Activity Component

const Violations = () => {
  const [activeTab, setActiveTab] = useState("1"); // Track which tab is active

  // Tab change handler to update active tab
  const onTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div >
      <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={onTabChange}>
        <Tabs.TabPane tab="Suspicious Activity" key="1">
          {activeTab === "1" && <SuspiciousActivity />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="USB Activity" key="2">
          {activeTab === "2" && <UsbActivity />}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default Violations;
