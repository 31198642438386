import React, { useState, useMemo, useEffect } from 'react';
import { Table, Modal, Select, Button } from 'antd';
import axios from 'axios';
import { Spin, Alert} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import '../Css/StatusTable.css';
import { API_BASE_URL1, API_BASE_URL2, API_BASE_URL_LOCAL_HOST } from '../../config';

const { Option } = Select;

const DashboardStatusTable = ({ data=[] }) => {
  const [data12, setData12] = useState([]); // This is already correct.
  const [employeesByStatus, setEmployeesByStatus] = useState([]);
  const [uniqueEmployeeIds, setUniqueEmployeeIds] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [clickedTotalData, setClickedTotalData] = useState([]); // New state for total data
  const EMPID = localStorage.getItem('EMPID') || '';
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL2}/alldata.php`, {
          params: { EMPID } // Send EMPID as a query parameter
        });
        const fetchedData = response.data.data || []; // Fallback to an empty array if undefined
        setData12(fetchedData);
        setColumns(response.data.columns || []); // Ensure columns has a default value
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data');
        setLoading(false);
      }
    };
    
    fetchData();
  }, [EMPID]);

  const filteredData = useMemo(() => {
    if (!data || data.length === 0) return [];
    return data.filter(row => 
      (selectedDepartment ? row['DEPARTMENT'] === selectedDepartment : true) &&
      (selectedTeam ? row['TEAM'] === selectedTeam : true)
    );
  }, [data, selectedDepartment, selectedTeam]);
  

  const uniqueShifts = useMemo(() => [...new Set(filteredData.map(row => row['SHIFT'] || ''))], [filteredData]);
  const uniqueDepartments = useMemo(() => [...new Set(filteredData.map(row => row['DEPARTMENT'] || ''))], [filteredData]);
  const uniqueTeams = useMemo(() => [...new Set(filteredData.map(row => row['TEAM'] || ''))], [filteredData]);

  const statusCountByShift = useMemo(() => uniqueShifts.reduce((acc, shift) => {
    acc[shift] = {
      ACTIVE: filteredData.filter(row => row['SHIFT'] === shift && row['CURRENT_STATUS'] === 'ACTIVE').length,
      INACTIVE: filteredData.filter(row => row['SHIFT'] === shift && row['CURRENT_STATUS'] === 'INACTIVE').length,
      OFFLINE: filteredData.filter(row => row['SHIFT'] === shift && row['CURRENT_STATUS'] === 'OFFLINE').length,
    };
    return acc;
  }, {}), [uniqueShifts, filteredData]);

  const totalCounts = useMemo(() => {
    return uniqueShifts.reduce((totals, shift) => {
      totals.ACTIVE += statusCountByShift[shift]?.ACTIVE || 0;
      totals.INACTIVE += statusCountByShift[shift]?.INACTIVE || 0;
      totals.OFFLINE += statusCountByShift[shift]?.OFFLINE || 0;
      return totals;
    }, { ACTIVE: 0, INACTIVE: 0, OFFLINE: 0 });
  }, [statusCountByShift, uniqueShifts]);

  const statusColumns = [
    { title: 'Shift', dataIndex: 'SHIFT', key: 'SHIFT', width: "5%" },
    {
      title: 'Online', dataIndex: 'ACTIVE', key: 'ACTIVE', width: "5%",
      render: (text, record) => (
        record.key === 'total' ? (
          <a onClick={() => handleTotalClick('ACTIVE')}>{text}</a>
        ) : (
          <a onClick={() => handleClick(record.SHIFT, 'ACTIVE')}>{text}</a>
        )
      )
    },
    {
      title: 'Inactive', dataIndex: 'INACTIVE', key: 'INACTIVE', width: "5%",
      render: (text, record) => (
        record.key === 'total' ? (
          <a onClick={() => handleTotalClick('INACTIVE')}>{text}</a>
        ) : (
          <a onClick={() => handleClick(record.SHIFT, 'INACTIVE')}>{text}</a>
        )
      )
    },
    {
      title: 'Offline', dataIndex: 'OFFLINE', key: 'OFFLINE', width: "5%",
      render: (text, record) => (
        record.key === 'total' ? (
          <a onClick={() => handleTotalClick('OFFLINE')}>{text}</a>
        ) : (
          <a onClick={() => handleClick(record.SHIFT, 'OFFLINE')}>{text}</a>
        )
      )
    },
  ];

  const statusData = useMemo(() => [
    {
      key: 'total',
      SHIFT: 'Total',
      ACTIVE: totalCounts.ACTIVE,
      INACTIVE: totalCounts.INACTIVE,
      OFFLINE: totalCounts.OFFLINE,
    },
    ...uniqueShifts.map(shift => ({
      key: shift,
      SHIFT: shift || 'N/A',
      ACTIVE: statusCountByShift[shift]?.ACTIVE || 0,
      INACTIVE: statusCountByShift[shift]?.INACTIVE || 0,
      OFFLINE: statusCountByShift[shift]?.OFFLINE || 0,
    })),
   
  ], [uniqueShifts, statusCountByShift, totalCounts]);

  const handleClick = (shift, status) => {
    setSelectedShift(shift);
    setSelectedStatus(status);
    setVisible(true);

    const uniqueIds = Array.from(new Set(filteredData
      .filter(row => row['SHIFT'] === shift && row['CURRENT_STATUS'] === status)
      .map(row => row['EMPID'])
    ));
    setUniqueEmployeeIds(uniqueIds);
  };
  const handleTotalClick = async (status) => {
    try {
      // Filter all data based on the selected status
      const totalFilteredData = filteredData.filter(row => row['CURRENT_STATUS'] === status);
      const empIds = totalFilteredData.map(row => row['EMPID']);
      
      // Fetch matching data from data12
      const matchingDataFromData12 = data12.filter(row => empIds.includes(row['EMPID']));
  console.log("matchh",matchingDataFromData12);
      // Update state with functional setter to ensure correct value
      setClickedTotalData(prevData => {
        console.log('Previous Data:', prevData);
        console.log('New Data:', matchingDataFromData12);
        return matchingDataFromData12;
      });
  
      // Show modal and update selected status
      setVisible(true);
      setSelectedStatus(status);
      setSelectedShift(null);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const exportToCSV = (data, columns) => {
    if (!data.length) return;

    const headers = columns.map(col => col.title).join(',');
    const rows = data.map(row => columns.map(col => row[col.dataIndex] || '').join(','));
    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows.join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedShift(null);
    setSelectedStatus(null);
    setClickedTotalData([]); // Clear total data when modal is closed
  };

  const handleRefresh = () => {
    // Reset selected department and team
    setSelectedDepartment(null);
    setSelectedTeam(null);
    // You can also refetch data here if necessary
  };

  useEffect(() => {
    if (selectedShift && selectedStatus) {
      const filteredEmployees = filteredData.filter(row =>
        row['SHIFT'] === selectedShift && row['CURRENT_STATUS'] === selectedStatus
      );
      setEmployeesByStatus(filteredEmployees);
    } else if (selectedStatus && !selectedShift) {
      // When only status is selected (for total clicks)
      const filteredEmployees = filteredData.filter(row =>
        row['CURRENT_STATUS'] === selectedStatus
      );
      setEmployeesByStatus(filteredEmployees);
    } else {
      setEmployeesByStatus([]);
    }
  }, [selectedShift, selectedStatus, filteredData]);
  const detailedData = useMemo(() => {
    if (!filteredData || filteredData.length === 0) return [];
    if (selectedStatus && !selectedShift) {
      return filteredData.filter(row => row['CURRENT_STATUS'] === selectedStatus);
    }
    return data12.filter(employee => uniqueEmployeeIds.includes(employee.EMPID));
  }, [selectedStatus, selectedShift, uniqueEmployeeIds, data12, filteredData]);
  

  const getDynamicColumns = () => {
    if (!data12 || data12.length === 0) return [];
    const sample = data12[0];
    const allKeys = Object.keys(sample);
    const filteredKeys = allKeys.filter((key, index) => index !== 15 && index !== 16);
    const filteredKeys1 = filteredKeys.slice(4);
    return filteredKeys1.map(key => ({
      title: key,
      dataIndex: key,
      key: key,
    }));
  };
  
  const detailedColumns = getDynamicColumns();
  console.log(detailedData);
   if (loading) return <Spin size="small" />;
   if (error) return <Alert message="Error" description={error.message} type="error" />;
  return (
    <div>
      <div>
      <div style={{ display: "flex", alignContent: "start" ,marginLeft:"-20px",marginTop:"-10px" }}>
          <Select
           size="small"
               className="custom-select"              
               style={{ marginLeft:"15px", width: "100px",   height:"30px",fontSize: "10px", padding: "2px 6px" }}
               dropdownStyle={{ width: "100px" }}
               placeholder={<span className="bold-placeholder">Departments</span>}
            onChange={value => setSelectedDepartment(value)}
            value={selectedDepartment}
          >
            {uniqueDepartments.map(dept => (
              <Option key={dept} value={dept}>{dept}</Option>
            ))}
          </Select>
    
          <Select
           size="small"
           className="custom-select"          
             style={{ width: "100px", fontSize: "10px",   height:"30px", padding: "2px 6px" }}
             dropdownStyle={{ width: "100px" }}
             placeholder={<span className="bold-placeholder">Teams</span>}
            onChange={value => setSelectedTeam(value)}
            value={selectedTeam}
          >
            {uniqueTeams.map(team => (
              <Option key={team} value={team}>{team}</Option>
            ))}
          </Select>
    
          <Button
    size="small"
    style={{     
      width: "75px",
      fontSize: "14px",
      padding: "2px 6px",
      color: "white",
      background: "#4096ff",        
      height:"30px"
    }}
    onClick={handleRefresh}
  >
    Refresh
  </Button>
        </div>
      </div>

     

<Table
        columns={statusColumns}
        dataSource={statusData}
        pagination={false}
        bordered
        className="custom-table"     
        scroll={null}  // Remove the scroll property
      
      />

      <Modal
        title={`Employees with status ${selectedStatus}${selectedShift ? ` in Shift ${selectedShift}` : ''}`}
        open={visible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Button 
          onClick={() => exportToCSV(selectedShift ? detailedData : clickedTotalData, detailedColumns)}
          style={{padding: '2px 6px', marginTop: '0.5%', height: '4%'}}
          icon={<DownloadOutlined />}
          type="default"
        >
          Export CSV
        </Button>
        <Table
  columns={detailedColumns}
  dataSource={selectedShift ? detailedData : clickedTotalData}  // Correct conditional rendering
  pagination={false}
  bordered
  className="detailed-table"
 
/>
      </Modal>
    </div>
  );
};
export default DashboardStatusTable;